import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const FormContainer = styled.div`
  height: 700px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ lightBg }) =>
    lightBg
      ? "linear-gradient(90deg, #f5a4c5 0%, #a484c7 100%)"
      : "linear-gradient(90deg, #401d3e 0%, #29162a 100%)"};

  @media screen and (max-width: 768px) {
    height: 800px;
  }

  // @media screen and (max-width: 480px) {
  //   height: 1300px;
  // }
`;

export const FormWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  max-width: 800px;
  padding: 10px;
  margin: 0 auto 15px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

export const CoverBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTitle = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  span {
    color: #01BF71; /* Highlight color */
    font-size: 20px;
    font-weight: bold; /* Optional: make the highlighted text bold */
  }
  em, i {
    font-style: italic; /* Italic text */
    color: #a2adcb; /* Optional: color for the italic text */
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  max-width: 800px;
  width: 50%;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;

export const StyledButton = styled.button`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  max-width: 800px;
  width: 50%;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;