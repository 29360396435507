import styled from "styled-components";

export const ServicesContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ lightBg }) =>
    lightBg
      ? "linear-gradient(90deg, #f5a4c5 0%, #a484c7 100%)"
      : "linear-gradient(90deg, #081448 0%, #282157 100%)"};

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(150px, auto); /* Ensures equal height for rows */
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  // @media screen and (max-width: 1000px) {
  //   grid-template-columns: 1fr 1fr;
  //   grid-auto-rows: minmax(150px, auto); /* Adjust for smaller screens */
  // }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
    grid-auto-rows: minmax(
      150px,
      auto
    ); /* Maintain equal spacing on small screens */
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 340px;
  width: auto;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    height: 170px;
    width: auto;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
  }
`;

export const ServiceTextWrapper = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    justify-self: start;
    justify-content: start;
    text-align: left;
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;
