import React from "react";
import ThankYouSection from "../components/ThankYouSection";

const ThankYou = () => {
  return (
    <>
      <ThankYouSection />
    </>
  );
};

export default ThankYou;
