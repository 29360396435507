import React, { useState } from "react";
import {
  FormWrapper,
  Title,
  Input,
  CoverBtnWrapper,
  FormContainer,
  FormTitle,
  Subtitle,
  InputWrapper,
  NavBtnLink,
  StyledButton,
} from "./WaitingListElements";
import { Button2 } from "../ButtonElements";
import { useHistory } from 'react-router-dom';


const WaitingListSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  const history = useHistory();
  function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const handleSubmit = (e) => {
    if (!email) {
      setError("Please enter your email");
      return;
    }
    if (validateEmail(email) === false) {
      setError("Please enter a valid email");
      return;
    }
    if (!name) {
      setError("Please enter your name");
      return;
    }

    setLoading(true);
    const data = {
      name: name,
      email: email,
      waitlist_id: 19964,
    };

    fetch("https://api.getwaitlist.com/api/v1/waiter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        history.push("/thankyou");
      })
      .catch((error) => {
        setLoading(false);
        console.log("error");
      });
  };
  return (
    <FormContainer id="waitlist">
      <FormWrapper>
        <FormTitle>Lamptern is Launching Soon—Get Ahead of the Crowd!</FormTitle>
        <Subtitle>
          Be the first to experience Lamptern by signing up for early access to
          our <span>beta version</span>. Enjoy a special{" "}
          <span>discounted credit subscription price</span>, exclusively for
          early users.
        </Subtitle>
        <Subtitle>
          <em>
            When the tool launches, you'll be the first to know. Stay tuned!
          </em>
        </Subtitle>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputWrapper>
          <CoverBtnWrapper>
            <StyledButton type="submit" disabled={loading}>
              I'm In!
            </StyledButton>
          </CoverBtnWrapper>
          {error && (
            <div className="text-center mt-2 text-xs text-red-500 px-6">
              {error}
            </div>
          )}
        </form>
      </FormWrapper>
    </FormContainer>
  );
};

export default WaitingListSection;
