import styled from "styled-components";

export const PricingContainer = styled.div`
  color: #fff;
  background: #210535;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const PricingWrapper = styled.div`
  display: grid;
  //   z-index: 1;
  height: 700px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 1100px;
    width: auto;
    padding: 0px 50px;
  }
`;

export const PricingRow = styled.div`
  display: grid;
  justify-content: center;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TopLine = styled.p`
  height: 10%;
  width: auto;
  color: #7a637f;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #a2adcb;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Heading2 = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #471a29;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const StyledList = styled.ul`
  height: 70%;
  width: auto;
  list-style-type: disc; /* Use disc bullets for the list */
  margin: 0;
  padding-left: 20px; /* Add space to the left for the bullets */
  font-size: 1rem;
  line-height: 1; /* Improve readability with line spacing */
  color: #fff;
  text-align: left;
  li {
    margin-bottom: 32px; /* Add space between list items */
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding-left: 15px; /* Reduce padding for smaller screens */
  }
`;

export const StyledList2 = styled.ul`
  height: 70%;
  width: auto;
  list-style-type: disc; /* Use disc bullets for the list */
  margin: 0;
  padding-left: 20px; /* Add space to the left for the bullets */
  font-size: 1rem;
  line-height: 1; /* Improve readability with line spacing */
  color: #754e59;
  text-align: left;
  li {
    margin-bottom: 32px; /* Add space between list items */
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding-left: 15px; /* Reduce padding for smaller screens */
  }
`;


export const Subtitle = styled.p`
  // max-width: 440px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 12px;
  color: #a2adcb;
`;

export const PricingCard = styled.div`
  background: linear-gradient(135deg, #4b0082 0%, #ff4500 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 500px;
  width: auto;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

export const PricingCard2 = styled.div`
  background: linear-gradient(135deg, #ffc0cb 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 500px;
  width: auto;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

export const PricingH1 = styled.h1`
  height: 20%;
  font-size: 2.5rem;
  color: #fff;
  margin-top: 64px;
  margin-bottom: 32px;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 32px;
  }
`;
