import React, { useState } from "react";
import {
  CoverContainer,
  CoverBg,
  VideoBg,
  CoverContent,
  CoverH1,
  CoverP,
  CoverBtnWrapper,
  ArrowForward,
  ArrowRight,
  Img,
} from "./CoverElements";
import { Button } from "../ButtonElements";
import Video from "../../images/cover_img.png";

const CoverSection = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  return (
    <CoverContainer id="about">
      <CoverBg>
        <Img src={Video} alt={"cover"} />
      </CoverBg>
      <CoverContent>
        <CoverH1>
          Revolutionize Your SEO Research—Pay Only for What You Use!
        </CoverH1>
        <CoverP>
          Affordable, flexible SEO tools tailored to your needs—No more
          overpriced subscriptions
        </CoverP>
        <CoverBtnWrapper>
          <Button
            to="waitlist"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            primary={1}
            dark={1}
            dark2={1}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Join Wait List {hover ? <ArrowRight /> : <ArrowForward />}
          </Button>
        </CoverBtnWrapper>
      </CoverContent>
    </CoverContainer>
  );
};

export default CoverSection;
