import React, { useState } from "react";
import {
  PricingContainer,
  PricingWrapper,
  PricingRow,
  Column1,
  Column2,
  TopLine,
  Heading,
  StyledList,
  PricingCard,
  PricingCard2,
  PricingH1,
  Heading2,
  StyledList2,
} from "./PricingElements";

const Pricing = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <PricingContainer id='pricing'>
      <PricingWrapper>
        <PricingH1>Pricing Type</PricingH1>
        <PricingRow>
          <Column1>
            <PricingCard>
              <TopLine>Customize Your Subscription with</TopLine>
              <Heading>Dynamic Subscription</Heading>
              <StyledList>
                <li>Set your desired credits per service each month.</li>

                <li>
                  System calculates your monthly subscription price
                  automatically.
                </li>

                <li>Recurring plan: Pay the same price every month.</li>

                <li>
                  Reload credits anytime for your favorite tools if you use them
                  up.
                </li>
              </StyledList>
            </PricingCard>
          </Column1>
          <Column2>
            <PricingCard2>
              <TopLine>Max Out Your Flexibility with</TopLine>
              <Heading2>Pay-As-You-Go</Heading2>
              <StyledList2>
                <li>No monthly subscription required.</li>

                <li>Start by depositing a minimum amount of $50.</li>

                <li>Pay only for the tools you use, as you use them.</li>

                <li>
                  Reload credits when needed to continue accessing services.
                </li>
              </StyledList2>
            </PricingCard2>
          </Column2>
        </PricingRow>
      </PricingWrapper>
    </PricingContainer>
  );
};

export default Pricing;
