export const problemObj = {
  id: "problem",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Do You Face This Problem?",
  headLine: "SEO Tools Shouldn't Break the Bank.",
  description1: "Think SEO tools cost too much?",
  // description1:
  //   "Current SEO tools are expensive, often with rigid pricing structures that don't reflect how much you actually use them.",
  description2:
    "As a digital marketer, you need flexibility, not a one-size-fits-all solution. We understand the challenge of managing tight budgets while needing top-tier tools to stay competitive.",
  buttonLabel: "Join Waitlist",
  imgStart: false,
  img: require("../../images/infoSection.png"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
