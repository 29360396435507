import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  MenuIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLink,
  NavLogo,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Img,
  ImgWrap,
} from "./NavbarElements";
import { Button } from "../ButtonElements";
const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <ImgWrap>
                <Img
                  src={require("../../images/elfa_ai_logo_ver2.png")}
                  alt={"logo"}
                />
              </ImgWrap>
            </NavLogo>
            <MenuIcon onClick={toggle}>
              <FaBars />
            </MenuIcon>
            <NavMenu>
              <NavItem>
                <NavLink
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="problem"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Problem
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="feature"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Tools
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="pricing"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Pricing
                </NavLink>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <Button
                to="waitlist"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                primary={1}
                dark={1}
                dark2={1}
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              >
                Sign Up
              </Button>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
