import React, { useState } from "react";
import {
  ThankYouContainer,
  Img,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  NavBtnLink,
} from "./ThankYouComponent";

import Correct from "../../images/correct.png";

const ThankYouSection = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  return (
    <ThankYouContainer>
      <Heading>Thank You!</Heading>
      <Subtitle>
        Thank you for joining the Wait List. We will inform you about the launch
        ASAP. See you soon!
      </Subtitle>
      <ImgWrap>
        <Img src={Correct}></Img>
      </ImgWrap>
      <BtnWrap>
        <NavBtnLink to="/">Back To HomePage</NavBtnLink>
      </BtnWrap>
    </ThankYouContainer>
  );
};

export default ThankYouSection;
