import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ThankYouContainer = styled.div`
  color: #fff;
  background: #f2f6ed;
  height: 100%;
  width: 100%;
  padding: 100px;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;
export const ImgWrap = styled.div`
  max-width: 400px;
  height: auto;
  margin: 0 auto; /* Center the ImgWrap horizontally */
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: bold;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  // max-width: 440px;
  margin-top: 60px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #5d6056;
  font-style: italic;
  text-align: center;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  justify-content: center;
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;
