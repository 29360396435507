import React, { useState } from "react";
import { Button } from "../ButtonElements";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  ArrowForward,
  ArrowRight,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  description1,
  description2,
  description3,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column1>
            <Column2>
              <TextWrapper>
                <TopLine>{description1}</TopLine>
                {/* <Subtitle darkText={darkText}>{description1}</Subtitle> */}
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                {/* <Subtitle darkText={darkText}>{description3}</Subtitle> */}
                <BtnWrap>
                  <Button
                    to="waitlist"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                  >
                    Join Wait List {hover ? <ArrowRight /> : <ArrowForward />}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
