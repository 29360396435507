import React from "react";
import Icon1 from "../../images/1.png";
import Icon2 from "../../images/2.png";
import Icon3 from "../../images/3.png";
import Icon4 from "../../images/4.png";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServiceTextWrapper,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="feature">
      <ServicesH1>Tools</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServiceTextWrapper>
            <ServicesH2>Keyword Research</ServicesH2>
            <ServicesP>
              Discover the right keywords to drive targeted traffic.
            </ServicesP>
          </ServiceTextWrapper>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServiceTextWrapper>
            <ServicesH2>SERP Tracking</ServicesH2>
            <ServicesP>
              Monitor your rankings and stay ahead of the competition.
            </ServicesP>
          </ServiceTextWrapper>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServiceTextWrapper>
            <ServicesH2>Backlink Analysis</ServicesH2>
            <ServicesP>
              Strengthen your site’s authority with in-depth backlink analysis.
            </ServicesP>
          </ServiceTextWrapper>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServiceTextWrapper>
            <ServicesH2>More Coming Soon</ServicesH2>
            <ServicesP>More tools to come to elevate your SEO games.</ServicesP>
          </ServiceTextWrapper>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
